var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CollectionPage',{ref:"collection",attrs:{"title":_vm.$t('nav.Tutorials'),"query":_vm.query,"variables":{ translation: _vm.$i18n.locale },"mappingFunction":function (data) {
      var input = data.allTutorialInfo.filter(function (ele) { return ele.content; });
      return input.map(function (ele) {
        return {
          url: ("/tutorial/" + (_vm.$i18n.locale) + "/" + (ele.url)),
          categories: ele.categories,
          isAnchorPublished: ele.isPublished,
          rank: ele.rank,
          title: ele.content.title,
          authors: ele.content.authors,
          modifiedTime: ele.content.modifiedTime,
          abstract: ele.content.abstract,
          isTransPublished: ele.content.isPublished,
        };
      });
    },"moreButtonText":_vm.$t('collectionPage.ReadMore'),"notClickableWhenNoContent":true}})}
var staticRenderFns = []

export { render, staticRenderFns }